import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../views/PricingView.vue')
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('../views/FAQsView.vue')
  },
  {
    path: '/trainers',
    name: 'trainers',
    component: () => import('../views/TrainersView.vue')
  },
  {
    path: '/trainers/find-a-pt',
    name: 'find-a-pt',
    component: () => import('../views/FindTrainersView.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/JoinView.vue')
  },
  {
    path: '/book',
    name: 'book',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/BookView.vue')
  },
  {
    path: '/book/:type',
    name: 'booktype',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/BookingPageView.vue')
  },
  {
    path: '/mypods',
    name: 'mypods',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/MyPodsView.vue')
  },
  {
    path: '/verify-email/:token',
    name: 'verifyemail',
    component: () => import('../views/VerifyEmail.vue')
  },
  {
    path: '/reset_user_password/:id',
    name: 'password',
    component: () => import('../views/PasswordView.vue')
  },
  {
    path: '/offers/:site_id/:offer_id',
    name: 'offers',
    component: () => import('../views/OffersView.vue')
  },
  {
    path: '/offices',
    name: 'offices',
    component: () => import('../views/OfficesView.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/CheckoutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/TermsView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!sessionStorage.userSession) {
      next({ name: 'join' });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
